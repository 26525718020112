.dialogTitle {
    text-align: center;
}

.dialogContentText {
    display: flex !important;
    flex-direction: column !important;
}

.dropDownSubjectSearch {
    width: 50% !important;
    padding: 0 0 1rem 0;
}