/* for simplicityReasons used css.file for files/FolderComponent */
.wrapper {
  width: 100%;
}
.folderList {
  padding-left: 0.5rem;
  width: max-content;
  list-style: none;
}

.folderList_Item {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}
.folderList_Item:hover {
  cursor: pointer;
  font-weight: bold;
  background-color: #ffffff;
}
.listItemName {
  /* width: fit-content;
  margin-right: 5px; */
  margin-top: 2px;
  margin-bottom: 2px;
}
.listItemName-selected {
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: bold;
}
.folderList_Item div.buttonWrapper {
  display: none;
}
.folderList_Item div.moveButtonWrapper {
  display: none;
}

.folderList_Item-selected {
  font-weight: bold;
  color: red;
}
.folderList_Item:hover div.buttonWrapper {
  display: flex;
}
.folderList_Item:hover div.moveButtonWrapper {
  display: flex;
}

.folderList_Item-active {
  display: flex;
  align-items: center;
  padding-left: 1em;
}
.folderList_Item-active:hover div.buttonWrapper {
  display: none;
}
.folderList_Item-active div.moveButtonWrapper {
  display: flex;
}

.fileList_Item {
  list-style-type: none;
  align-items: center;
  margin-bottom: 10px;
}
.fileList_Item:hover {
  cursor: pointer;
}
.fileList {
  padding-left: 0;
}

.folderOpenIcon {
  margin-right: 5px;
}

.fileTableSvg {
  width: 1.2rem;
  height: 1.2rem;
}

.fileTableNameLink {
  text-decoration: none;
  cursor: pointer;
  color: var(--text2);
  &:hover {
    color: var(--background2);
  }
}
