.mainContainer {
    display: flex;
    flex-direction: column;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    width: 50vw;
}

.buttonContainer {
    display: flex;
    margin-top: 0.5rem;
    gap: 0.5rem;
}

.sectionHeader {
    padding: 0;
    margin: 1rem 0 0 0;
}

.listContainer {
    padding: 0;
    margin: 0;
}

.listItem {
    display: flex;
    align-items: center;
}

.createButton {
    justify-content: flex-start;
}

.moveButton {
    padding: 0;
}