

.navigationButton {
  color: var(--text2);
  font-style: normal;
}

.navigationButton:hover a {
  color: var(--text2);
}

.navigationNavlink {
  color: var(--text2);
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  justify-content: center;
}

.navigationNavlinkActive {
  color: var(--text2);
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 800;
  width: 100%;
  justify-content: center;
}


