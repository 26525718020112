.fullWidth {
    width: 100%;
}

.outlinedInputRounded {
    border-radius: 0.5rem !important;
}

.phoneInputLabel {
    margin-bottom: 0.25rem !important;
}