.iconButton {
    position: absolute !important;
    right: 2rem !important;
    border-radius: 50% !important;
    font-weight:bold;
    padding: 0.75rem !important;
    font-size: 1.25rem;
}

.avatar {
    width: 36px;
    height: 36px;
}

.userDetailsWrapper {
    display: flex;
    flex-direction: column;
}

.iconContainer {
    display: none !important;
}



/* Media Query */
/* For mobile phones: */
@media (max-width: 600px) {
    .iconButton {
        right: 0.5rem !important;
    }

    .iconContainer {
        display: block !important;
        float: right;
        position: absolute;
        right: 0.5rem;
        top:0;
    }
    .listItem {
        width:80%
    }

    .closeIcon {
        font-size: 1.7rem !important;
    }
}