.loginWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background-size: cover;
}

.formWrapper {
    width: 80%;
    margin: 2rem 0;
}

.wideForm {
    width: 50%;
    margin: 0;
}

.centerStyle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
}

.noWrap {
    flex-wrap: nowrap;
}

.autoWidthButton {
    width: auto;
}

.fullWidthButton {
    width: 100%;
}

.errorMessage {
    padding: 0.625rem;
    margin: 4%;
    color: #ba3939;
    background: #ffe0e0;
    border: 1px solid #a33a3a;
    border-radius: 1rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.messageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
}

.passwordLabel {
    display: block;
}

.expiredLinkModal {
    display: flex;
    justify-content: center;
}

.errorSpan {
    padding: 0 5px;
}

.emailLink {
    text-decoration: none;
}

.defaultInputLabel {
    color: white;
    margin-top: 1em;
    margin-bottom: 1em;
    gap: 0.5rem;
    width: 100%;
}