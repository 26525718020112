.outlinedInputRoot {
    border-radius: 0.25rem !important;
}

.outlinedInputNotchedOutlineNone {
    border: none !important;
}

.inputBaseInputPadding {
    padding: 0.75rem !important;
}

.inputBaseInputDefaultBackground {
    background-color: #FFF;
}