.container {
    width: 50em !important;
    align-content: flex-start !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.heading {
    display: flex;
    flex-direction: column;
}

.showInvitedButton {
 width: max-content;
}

.linkNoUnderline {
    color: #8c1ec8;
    text-decoration: none;
}

.linkUnderlined {
    color: #8c1ec8;
    text-decoration: underline;
}

.submitButton {
    float: inline-end;
}