.tagLabel {
    margin-bottom: 0;
    max-width: 400px;
    width: 100%;
}

.tagList {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 !important;
}
.taglist_container{
    display: flex;
    margin: 1rem 0;
    gap: 0.25rem;
}
.taglist_chipItem{
    width: fit-content !important;
    background-color: #ff6428 !important;
    color: #ffffff !important;
    border-radius: 0.25rem !important;

}

.cancelButton {
    display: block;
    float: left;
}
.changeEditorTypeButton{
    display: block;
    margin: 0 0 1rem 0 !important;
    
}
.textArea{
    margin-top: 1rem;
}