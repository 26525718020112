.accordion {
    z-index: 1;
}

.accordionExpanded {
    background: lightgray !important;
}

.accordionSummaryExpanded {
    font-weight: bold;
}

.wrapper {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.mandatoryCheckbox {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
}

.visibilityInputStyle {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}