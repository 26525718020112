.backdrop {
    color: #ffffff;
    z-index: 9999;
    /* zIndex: (theme) => theme.zIndex.drawer + 1 */
    /* theme.zIndex.drawer in MUI is typically 1300 by default */
    /* use a high value to ensure it overlays correctly */
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.companyNameLink {
    text-decoration: none;
    color: #8c1ec8;
}

.buildingName {
    font-weight: bold;
}