/* .defaultWrapper {
    max-width: 1200px;
    margin: 0 auto;
} */

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.buttonWrapper {
    margin-top: 1em;
}

.cancelButton {
    display: block;
    float: left;
}

.submitButton {
    display: block;
    float: right;
}