.flexContainer {
    display: flex;
    gap: 1rem;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.noMargin {
    margin: 0;
}

.uploadButton {
    float: right ;
    padding: 0;
    margin-bottom: 0.25rem;
 
}

.generalListItemWrapper__sendEmailBtn {
    background-color: #ff6428 !important;
    color: #fff !important;
    margin: 0.625rem 0 ;
}

.generalListItemWrapper__uploadBtn {
    background-color: #dc3545 !important;
    color: #fff !important;
    margin: 0.625rem 0 ;
}

.generalListItemWrapper__BtnCase{
    background-color: #8c1ec8 !important ;
    color: #fff !important;
    margin: 0.625rem 0 
}
.generalListItemWrapper__BtnCase_disabled{
    background-color: #9e9e9e!important ;
    color: #fff !important;
    margin: 0.625rem 0 
}