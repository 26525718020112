.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background-size: cover !important;
}

.errorContainer {
    padding: 0.625rem;
    margin: 4%;
    color: #ba3939;
    background: #ffe0e0;
    border: 1px solid #a33a3a;
    border-radius: 1rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorMessageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
}

.errorMessageSpan {
    padding: 0 5px;
}

.emailStyle {
    text-decoration: none;
}

.modalContainer {
    display: flex;
    justify-content: center;
}