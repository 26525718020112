.link_white {
    color: #fff;
    text-decoration: none;
}

.link_purple {
    color: #8c1ec8;
    text-decoration: none;
}

.boxContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;
    white-space: normal;
}