.button {
    padding: 0.5rem 1rem ;
    border-radius: 0.75rem 
}

.card {
    padding: 1rem;
    background-color: #f4f2f2 !important;
    border-radius: 0.75rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cardTitle {
    font-weight: 700 !important;
}

.cardButton {
    padding: 0.5rem 1rem ;
    border-radius: 0.75rem 
}

.buttonsWrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0.5rem 0;
}