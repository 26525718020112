.radioButtonInput {
    display: none !important;
}

.radioLabel {
    display: inline-flex !important;
    align-items: center !important;
    cursor: pointer !important;
    width: 100% !important;
}

.labelRequired {
    color: red !important;
}

.radioGroupLabel {
    display: flex !important;
    margin-bottom: 0.25rem !important;
}

.radioButtonWrapper {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    gap: 0.5rem !important;
}
.radioButtonWrapperThirdOption {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 0.5rem !important;
}

.customRadioButton {
    display: flex !important;
    width: 100% !important;
    /* border: 1px solid #ccc; */
    border-radius: 0.25rem !important;
    padding: 0.5rem 1rem !important;
    justify-content: center !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    background-color: #f4f2f2;
}

.customRadioButton:hover {
    background-color: #bcbcbc;
}

.radioButtonInput:checked+.customRadioButton {
    background-color: #a34bd3;
    color: #FFFFFF;
}