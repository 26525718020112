.formWrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;

}
.headingWrapper {
    display: flex;
justify-content: flex-start;
align-items: center;
position: relative;

}
.heading {
    word-wrap: break-word;
    white-space: normal;
    max-width: 80%;
    margin-right: auto;
}


.dateTimePickerWrapper {
    width: 100%;
    margin-top: 1rem;
}

.dateTimePicker {
    width: 100%;
}

.tableWrapper {
    margin-top: 1rem;
}

.tableRowActions {
    display: flex;
    gap: 1rem;
}

.tableTopToolbar {
    display: flex;
    gap: 0.5rem;
}

.buttonWrapper {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
}

.dropDown__appointmentStatus {
    position: relative !important;
    z-index: 11 !important;
}

.dropDown__team,
.dropDown__executedBy,
.dropDownSubjectSearch {
    position: relative !important;
    z-index: 10 !important;
}
