.timeSlotsWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem !important;
}

.fullHeight {
    height: 100% !important;
}
.backdrop {
    color: #ffffff;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.3) !important;
}

/* Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {
    .heading {
        text-align: center;
    }
}