.loginWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background-size: cover;
}

.backdrop {
    color: #ffffff;
    z-index: 9999;
    /* zIndex: (theme) => theme.zIndex.drawer + 1 */
    /* theme.zIndex.drawer in MUI is typically 1300 by default */
    /* use a high value to ensure it overlays correctly */
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.registerFormWrapper {
    width: 80%;
    margin: 2rem 0;
}

.registerFormWrapperWide {
    width: 50%;
    margin: 0;
}

.checkboxWithControl_labelSpan {
    color: white;
    font-weight: bold;
}

.centerStyle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxStyle {
    display: flex;
    margin-top: 1rem;
    gap: 0.5rem;
}

.contactUsContainer {
    color: white;
    margin-top: 1rem;
    text-align: center;
}

.emailLink {
    color: white;
}

.emailLink:hover {
    color: #8c1ec8;
}

.displayBlock {
    display: block;
}
.register_submitButton {
    padding: 0.75rem ;
    border-radius: 0.25rem ;
    width: 100% ;
  }

  .register_navigateButton {
    padding: 0.75rem ;
    border-radius: 0.25rem ;
    width: 50%;
  }