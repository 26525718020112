.generalListItemWrapper {
    border-radius: 0.75rem;
    gap: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 8px 24px -4px #E0E0E040;
}

.generalListItemWrapperNoHover {
    border-radius: 0.75rem;
    gap: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 8px 24px -4px #E0E0E040;

}

.generalListItemWrapper:hover {
    box-Shadow: 0 0 5px #8c1ec8;
    cursor: pointer;
}

.generalListItemContent {
    float: left;
}

.generalListItemButton {
    float: left;
    margin-right: 0.625rem;
}

/*  Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {

    .generalListItemWrapper,
    .generalListItemWrapperNoHover {
        display: block;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .generalListItemContent {
        float: none;
    }

    .buttonsContainer {
        justify-content:space-evenly;
    }

    .generalListItemButton {
        margin-right: 0 !important;
    }
}