.tableHeaderCell {
    width: 150px;
    padding: 10px;
}

.tableCell {
    padding: 10px;
}

.statusCell {
    padding: 10px;
    font-weight: bold;
}

.selectedDepartmentChip {
    background-color: #8c1ec8 !important;
    color: white !important;
    margin-right: 0.25rem !important;
    margin-top: 0.25rem !important;
}