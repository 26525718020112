.container {
    width: 100%;
}

.tableStyle {
    width: 100%;
}

.col20 {
    width: 20%;
}

.col40 {
    width: 40%;
}

.cellStyle {
    padding-left: 10px;
    padding-right: 10px;
}

.stickyContainer {
    height: fit-content;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column !important;
    gap: 1rem;
}

.dateButtonContainer {
    column-gap: 1rem;
    justify-content: center;
}

.dateButton {
    margin-top: 1rem;
}

.generalListItemWrapper {
    width: 90% !important;
}