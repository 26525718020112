.toolbarButtonContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.toolbarButton {
    background-color: #8c1ec8;
}

.dateContainer {
    font-weight: 700;
}

.datePickerContainer {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
}