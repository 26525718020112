.selectWrapper {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.datePicker,
.dateTimePicker {
    width: 100%;
}

.dropDownSubjectSearch {
    z-index: 10 !important;
    overflow-y: visible !important;
}

.textField {
    width: 100%;
}

.preWhiteSpace {
    white-space: pre !important;
}

.noDisplay {
    display: none !important;
}

.resizeVertical {
    resize: vertical !important;
}

.fullWidth {
    width: 100%;
}

.phoneInputLabel {
    margin-bottom: 0.25rem !important;
}

.checkBox {
    display: flex;
    gap:0.5rem;
}
.labelRequired {
    color: var(--error);
}