.entriesModalContainer {
    max-height: 35vh;
    padding: 1.25rem;
}

.entriesSection {
    display: flex;
    justify-content: space-between;
}

.entriesSectionColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.boldText {
    font-weight: bold;
}

.incorrectEntriesText {
    font-weight: bold;
    margin: 0;
}

.errorListItem {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.dropDownContainer {
    z-index: 10 !important;
}

.dropDownSubjectSearch {
    width: 50% !important;
}