.backdrop {
    color: #fff;
    z-index: 9999;
    /* zIndex: (theme) => theme.zIndex.drawer + 1 */
    /* theme.zIndex.drawer in MUI is typically 1300 by default */
    /* use a high value to ensure it overlays correctly */
    background-color: rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    border-radius: 0.75rem;
}

.generalListItemWrapper {
    text-align: center !important;
    justify-content: center !important;
}

.generalListItemWrapper__slotFree {
    background-color: #66bab1 !important;
    color: #fff !important;
}

.generalListItemWrapper__slotTaken {
    background-color: #ff8353 !important;
    color: #fff !important;
}

.generalListItemWrapper__slotDeleted {
    background-color: #f4f2f2 !important;
    color: #bcbcbc !important;
}

.generalListItemContent__slotDeleted {
    text-decoration: line-through !important;
}