.submitButton {
    display: block;
    float: right;
}
.fullWidth {
    width: 100%;
}
.heading {
    max-width: 65%;
    margin: 0 auto;
}
.formPaperWrapper {
    max-width: 65%;
    margin: 0 auto;
    border-radius: 0.75rem;
    overflow: scroll;
    padding: 0.75rem;
}
.datePicker {
    width:100%
}