.tableHeading_withChip {
  display: flex;
  gap: 0.5rem;
  align-items: last baseline;
  margin: 0;
  font-size: 2rem;

}

.tableHeading{
  font-size: 2rem;
  margin: 0;

}
