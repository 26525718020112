.inputContainer {
    display: flex;
    flex-direction: column;
}

.label {
    display: flex;
    margin-bottom: 0.25rem;
}

.labelRequired {
    color: red;
}

.labelDisabled {
    color: rgb(188, 188, 188);
}

.inputField {
    border: 1px solid #bcbcbc;
    outline: none;
    padding: 0.75rem;
    border-radius: 0.25rem;
    transition: border-color 0.3s ease;
}

.inputFieldLightGray {
    color: lightgray;
}