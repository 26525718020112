.drawer {
  background-color: var(--warmGrey3);
  z-index: 10001 !important;
}

.navigationDrawer_headerWrapper {
  display: flex;
  justify-content: flex-end;
}

.boxWrapper {
  height: 0.5rem;
  background-color: #FFF;
  width: 350px;
}

.navigationDrawer_list {
  padding-top: 0;
}

.dropDown__naviagationDrawer {
  margin: 0.25rem 0.25rem;
}


/* Media Query */
/* For mobile phones: */
@media (max-width: 600px) {
  .boxWrapper {
    width: 100vw;
  }
}

/* For tablets: */
@media (max-width: 1024px) {

  .iconContainer {
    margin-top: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .closeIcon {
    font-size: 2rem !important;
  }

  .divider {
    margin-top: 0.5rem;
  }
}