.leftColumnWrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: clip;
}

.flexContainer {
    display: flex;
}

.label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name {
    white-space: normal;
    overflow-wrap: break-word;
}