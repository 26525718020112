.folderListItemContainer {
    padding: 0;
    flex-wrap: nowrap;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.listItemContainer {
    padding: 0;
    padding-left: 0.5rem;
}

.listItemTextRoot {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    justify-content: space-between !important;
}