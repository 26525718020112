.formWrapper {
    margin-left: 1.25rem;
}

.probeWrapper {
    display: flex;
    justify-content: space-between;
}

.heading {
    margin: 0;
}

.generalListItemWrapper__details {
    margin: 0 0 0.75rem 0 !important;
    background-color: #f4f2f2 !important;
}

.generalListItemWrapper__probe {
    margin: 0 0 0.75rem 0 !important;
    background-color: #8c1ec8 !important;
    color: #fff !important;
}