.modalContent {
    padding-top: 1rem !important;
}

.downloadButton {
    align-self: flex-end !important;
}

.chip {
    width: fit-content !important;
    background-color: #ff6428 !important;
    color: #ffffff !important;
}

.deleteIcon {
    color: inherit !important;
}

.gridContainer {
    margin-top: 1rem !important;
}

.accordion {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
}

.accordionSummaryContent {
    margin: 0 !important;
}