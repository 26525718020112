.container {
    padding: 1rem !important;
    background-color: #fff;
    border-radius: 0.75rem;
    width: 100%;
}

.accordion {
    box-shadow: none !important;
}

.accordionTitle {
    font-size: 1.25rem;
}

.noPadding {
    padding: 0 !important;
}

.primaryText {
    font-size: 0.875rem !important;
    line-height: 1.43 !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

.secondaryText {
    font-weight: 600 !important;
    font-size: 1rem !important;
    color: #8c1ec8 !important;
}