.label {
    display: flex;
    margin-bottom: 0.25rem;
}

.labelRequired {
    color: red;
    margin-left: 0.25rem;
}

.textArea {
    border: 1px solid #bcbcbc;
    white-space: preserve;
    outline: none;
    padding: 0.75rem;
    border-radius: 0.25rem;
    width: 100%;
    transition: border-color 0.3s ease;
}

.textAreaWithCustomWidth {
    width: auto;    /* For cases when cols is defined */
}