.columnHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
}

.columnTitle {
    text-align: center;
    margin: 5px;
}

.formControl {
    width: 35%;
}