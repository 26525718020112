.mobileTimePickerWrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.datePickerWrapper {
    gap: 1rem !important;  /* equivalent to gap={2} in Material UI */
    margin: 0 !important;
    width: 100% !important;
    flex-direction: column !important;
}

.heading {
    font-size: 1rem;
    margin: 0;
}

.linkNoUnderline {
    color: #8c1ec8;
    text-decoration: none;
}

.linkUnderlined {
    color: #8c1ec8;
    text-decoration: underline;
}

.checkBoxLabel {
    display: flex;
    gap: 0.5rem;
}
.submitButton {
    width: max-content;
}