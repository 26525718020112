.editorContainer {
    margin-top: 1rem;
}

.footerContainer {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.lastSaved {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
}