.itemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemWrapperNoAlign {
    display: flex;
    justify-content: space-between;
}

.noMargin {
    margin: 0;
}

.itemContent {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.itemContentNoAlign {
    display: flex;
    gap: 0.5rem;
}

.expandMoreButton {
    top: 1rem !important;
}

.collapseContent {
    width: 100% !important;
    padding: 0 !important;
}

.label {
    font-weight: 700 !important;
}

.generalListItemWrapper {
    background-color: #8c1ec8 !important;
    color: #fff !important;
    margin: 0 0 0.625rem 0 !important;
}
.generalListItemWrapperDisabled {
    background-color: var(--disabledGrey) !important;
    color: #fff !important;
    margin: 0 0 0.625rem 0 !important;
}