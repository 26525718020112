.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.errorIcon {
    color: #f44336; /* Corresponds to Material-UI 'error' color */
}

.successIcon {
    color: #4caf50; /* Corresponds to Material-UI 'success' color */
}

.errorMessage {
    display: inline;
    color: #000;    /* Corresponds to Material-UI 'text.primary' color */
}

.listItem {
    font-style: italic;
    font-weight: bold;
}

.modalTitle {
    text-align: center;
}

.modalList {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;  /* Corresponds to Material-UI 'background.paper' */
}