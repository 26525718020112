.errorIcon {
    color: #f44336; /* Corresponds to Material-UI 'error' color */
}

.listItemTextPrimary {
    color: red; /* Custom color for primary text in ListItemText */
}

.dialogTitle {
    text-align: center;
}