.paperContainer {
    padding: 0.75rem;
    border-radius: 0.75rem;
    max-width: 1000px;
    margin: 0 auto;
}

.fullWidth {
    width: 100%;
}

.textArea__label {
    color: black;
}

.textArea__field {
    width: 100% !important;
}