.iconWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rightColumnWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}