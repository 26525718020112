.dropdownContainer {
    display: flex;
    flex-direction: column;
}

.dropDownWithRefLabel {
    display: flex;
    align-items: center;
}

.labelRequired {
    color: red;
    margin-left: 0.25rem;
}