.formStyle {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.passwordInput__label {
    color: black !important;
    margin-bottom: 0 !important;
}

.passwordInput__input {
    border: 1px solid #bcbcbc !important;
}
.submitButton{
    width: fit-content;
    align-self: flex-end;
}