.confirmationModalWrapper {
    width: 100%;
    max-width: 650px !important
    
}

.confirmationModalTitle {
    text-align: center;
    padding: 1.25rem 1.5rem !important;
}

.confirmationModalContent {
display: flex;
justify-content: center;
padding: 0 1.5rem 1.5rem !important;
}

.confirmationModalActionWrapper {
    display: flex;
    justify-content: space-between !important;
    padding: 0 1.5rem 1.25rem !important;
}
