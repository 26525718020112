/*  Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {
    .uploadButton {
        width: 100% !important;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fileTableName {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    align-items: center;
    white-space: normal;
    word-break: break-word;
}

.undownloadedFileBold {
    font-weight: 700;
}

.mobileActionButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.mobileActionButton {
    margin-top: 0.5rem !important;
    width: 100%;
}

.actionButtonIcon,
.mobilePaginationScrollToggleIcon {
    margin-right: 0.5rem;
}

.mobilePaginationScrollToggle {
    background-color: #F4F2F2 !important;
    color: rgba(0, 0, 0, 0.7) !important;
}

.toolBarItemsContainer {
    display: flex;
    gap: 0.25rem;
}

.noMargin {
    margin: 0 !important;
}

.statusIcons {
    display: flex;
    justify-content: space-evenly;
}

.tableRow {
    animation: fadeIn 0.5s ease-in-out;
}

.bottomToolbarWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
}

.numOfFilesDisplayed {
    color: rgba(0, 0, 0, 0.6);
}

.spinnerContainer {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.loadingMoreSpinner {
    transition: opacity 0.5s ease;
    opacity: 0;
}

.loadingMoreSpinnerVisible {
    opacity: 1;
}

.loadingMoreSpinnerHidden {
    opacity: 0;
}

.scrollToTopButton {
    position: fixed ;
    bottom: 1rem;
    right: 1rem ;
    z-index: 9999 ;
    border-radius: 50%;
    padding: 0.75rem;
}

.mobilePaginationScrollToggle {
    width: 100%;
    margin-top: 0.5rem !important;
}

/*  Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {
    .toolBarItemsContainer {
        display: block;
    }

    .scrollToTopButton {
        bottom: 5rem !important;
        right: 0.5rem !important;
    }
}