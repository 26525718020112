.tooltipList {
    margin: 0;
    padding-left: 0.5rem;
    font-size: 0.8rem;
}

.selectInputWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.addSelectInputButton {
    width: 15vw;
}

.templateList {
    padding-top: 1rem;
}