.form {
    margin-top: 1rem;
}

.editorWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}
.buttonWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: fit-content;
}