.formStyle {
    display: flex;
    flex-direction: column;
}

.inputStyle {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    gap: 0.5rem !important;
}
.submitButton{
    width: fit-content;
    align-self: flex-end;
}