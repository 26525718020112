.deleteModalWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    border: var(--defaultBorderWidth) solid lightgray;
    border-radius: var(--defaultBorderRadius);
    padding: 7px;
    text-align: center;
    position: absolute;
    top: 30vh;
    left: 40vw;
    right: auto;
    bottom: auto;
    z-index: 10;
    background-color: #fff;
}

.spanStyle {
    color: red;
    font-weight: 400;
}

.buttonWrapper {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}