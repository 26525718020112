.link {
    text-decoration: none;
    color: #8c1ec8;
}

.dropDownSubjectSearch {
    width: 50% !important;
    padding: 0 0 1rem 0;
}

.buildingName {
    font-weight: bold;
}

.stacked_bar_chart {
    width: 100%;
}

.stacked_bar {
    display: flex;
    height: 1.75rem;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid #ccc;
}

.bar_segment {
    height: 100%;
    transition: width 0.3s ease-in-out;
}

.customActionButtonWrapper {
    display: flex;
    gap: 0.5rem;
}

.modalContent {
    padding-top: rem !important;
}

.chip {
    width: fit-content !important;
    background-color: #ff6428 !important;
    color: #ffffff !important;
    height: unset !important;
}

.deleteIcon {
    color: inherit !important;
}

.entriesModalContainer {
    width: 25vw;
    max-height: 35vh;
    padding: 1.25rem;
}

.entriesSection {
    display: flex;
    justify-content: space-between;
}

.entriesSectionColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.boldText {
    font-weight: bold;
}

.incorrectEntriesText {
    font-weight: bold;
    margin: 0;
}

.errorListItem {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}




/* Global styles for react-datepicker */
:global(.react-datepicker__day--outside-month) {
    visibility: hidden;
}

:global(.react-datepicker__month) {
    max-height: 350px;
}

:global(.react-datepicker) {
    margin-top: 1rem;
}