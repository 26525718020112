.link_white {
    color: #fff;
    text-decoration: none;
}

.link_purple {
    color: #8c1ec8;
    text-decoration: none;
}

.paperContainer {
    padding: 0.75rem;
    border-radius: 0.75rem;
    height: 100%;
}