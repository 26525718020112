.container {
    width: 100% !important;
}

.fileListWrapper {
    margin-top: 1rem !important;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.heading {
    padding: 0 !important;
}

/*  Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {
    .heading {
        padding-top: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .fileListWrapper {
        margin-top: 0 !important;
    }

    .heading {
        width: 100%;
    }
}