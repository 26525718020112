.fileTableName {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    align-items: center;
    white-space: normal;
    word-break: break-word;
}

.buttonLink {
    text-decoration: none;
    color: #fff;
}

.toolBarItemsContainer {
    display: flex;
    gap: 0.25rem;
}