.label {
    display: flex;
    margin-bottom: 0.25rem;
}

.dateInput {
    border: 1px solid #bcbcbc;
    outline: none;
    padding: 0.75rem;
    border-radius: 0.5rem;
    width: 100%;
}