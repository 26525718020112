.container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.noPadding {
    padding: 0 !important;
}

.displayBlock {
    display: block !important;
}

.typoUnderlined {
    display: block !important;
    text-decoration: underline !important;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 2rem;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.emailLink {
    text-decoration: none;
}

.heading,
.secondaryText {
    text-align: center;
}

.tableContainer {
    width: 80%;
    padding: 1rem;
}

.table {
    width: 100%;
    border: 0.5px solid black;
    border-radius: 0.75rem;
    padding: 1rem;
    border-collapse: inherit;
    margin-bottom: 2rem;
}

.tableCol1 {
    width: 20%;
}

.tableCol2 {
    width: 80%;
}

.tableHeader {
    font-weight: 700;
    padding: 0.75rem;
}

.tableData {
    padding: 0.75rem;
}

.tableDescription {
    font-weight: 700;
    padding: 0.75rem;
    position: absolute;
}

.button {
    width: 100% ;
    margin-bottom: 2rem ;
    font-weight: 700;
}

/* Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {
    .tableContainer {
        width: 100%;
        padding: 0.25rem;
        text-align: center;
    }

    .table {
        padding: 0.25rem 0.25rem 0.75rem 0.25rem;
    }

    .tableRow {
        display: block;
    }

    .tableCol1,
    .tableCol2 {
        width: 100%;
    }

    .tableData {
        display: block;
        width: 100%;
        padding: 0.5rem 0.5rem 0 0.5rem;
    }

    .tableHeader,
    .tableDescription {
        display: block;
        width: 100%;
        padding: 1rem 0.5rem 0 0.5rem;
    }

    .displayBlock,
    .typoUnderlined {
        text-align: center;
    }
}

/* For tablets (large): */
@media (max-width: 1281px) {
    .tableDescription {
        position: static;
    }
}