.dataProtectionInformation h1 {
    margin-top: 2em;
    margin-bottom: 1em;
}

.dataProtectionInformation h2,
h3,
h4 {
    margin-top: 2em;
    margin-bottom: 1em;
}