.outletContent {
    height: 100%;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
    /* width: calc(100vw - 4rem); */
}

.backdrop {
    color: #ffffff;
    z-index: 9999;
    /* zIndex: (theme) => theme.zIndex.drawer + 1 */
    /* theme.zIndex.drawer in MUI is typically 1300 by default */
    /* use a high value to ensure it overlays correctly */
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.backdropContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

/*  Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {
    html {
        font-size: 14px;
    }

    .outletContent {
        padding: 0.5rem !important;
    }
}